import React, { Component } from 'react'
import { createStyles, withStyles, Button, Typography } from '@material-ui/core'
import { reduxForm } from 'redux-form'

import { formId } from '../../constants/contact'
import Fields from './Fields'
import apiConfig from '../../api'

const styles = () => {
  return createStyles({
    container: {
      padding: '1.25rem 2.5rem',
      maxWidth: '37.5rem',
      boxShadow: '3px 11px 14px 4px #f3f3f3',
      margin: '0 auto',
      backgroundColor: '#fffdfc',
    },
    button: {
      boxShadow: 'none',
      borderRadius: 0,
      padding: '0.5625rem 0.9375rem',
    },
  })
}

class Form extends Component {
  state = {
    succeeded: null,
  }

  setSucceeded = (succeeded) =>
    this.setState((prevState) => ({ ...prevState, succeeded }))

  handleError = (error) => {
    console.error(error)
    this.setSucceeded(false)
  }

  createMessage = async (values) => {
    const response = await apiConfig.post('/messages.json', values)
    if (response.status === 200) {
      return this.setSucceeded(true)
    }
    return this.setSucceeded(false)
  }

  handleSubmit = async (values) => {
    try {
      await this.createMessage(values)
    } catch (error) {
      this.handleError(error)
    }
  }

  renderForm = () => {
    const { handleSubmit, classes } = this.props
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Fields />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Typography>Submit</Typography>
        </Button>
      </form>
    )
  }

  renderMessage = () => {
    const { succeeded } = this.state
    if (succeeded) {
      return (
        <Typography>Thank you! Your submission has been received!</Typography>
      )
    }
    return (
      <Typography>
        Sorry, there was a problem. Please try again later.
      </Typography>
    )
  }

  renderBody = () => {
    const { succeeded } = this.state
    if (succeeded === null) {
      return this.renderForm()
    }
    return this.renderMessage()
  }

  render() {
    const { classes } = this.props
    const body = this.renderBody()
    return <div className={classes.container}>{body}</div>
  }
}

const StyledForm = withStyles(styles)(Form)

export default reduxForm({ form: formId })(StyledForm)
