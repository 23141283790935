import React, { Component } from 'react'
import { Field } from 'redux-form'

import Input from './Input'

export class TextField extends Component {
  render() {
    return <Field component={Input} type="text" {...this.props}></Field>
  }
}

export default TextField
