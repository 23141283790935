import { createStyles, Grid, withStyles, Typography } from '@material-ui/core'
import React, { Component } from 'react'

import Image from './Image'
import Layout from '../components/Layout'
import Form from './Form'

const styles = () => {
  return createStyles({
    section: {
      padding: '2.5rem 0',
      backgroundColor: '#fffbf8',
    },
    title: {
      marginBottom: '2.8125rem',
      fontSize: '2rem',
      fontWeight: 600,
      marginTop: '3.75rem',
    },
    image: {
      width: '100%',
      height: 'auto',
      maxWidth: '57rem',
      maxHeight: '42.5rem',
    },
  })
}

class Contact extends Component {
  render() {
    const { classes } = this.props
    return (
      <Layout>
        <section className={classes.section}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Image className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h1" className={classes.title} align="center">
                Request a demo
              </Typography>
              <Form />
            </Grid>
          </Grid>
        </section>
      </Layout>
    )
  }
}

export default withStyles(styles)(Contact)
