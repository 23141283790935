import React, { Component } from 'react'
import {
  Typography,
  createStyles,
  withStyles,
  OutlinedInput,
} from '@material-ui/core'

const styles = () => {
  return createStyles({
    label: {
      display: 'block',
      marginBottom: '0.625rem',
    },
    text: {
      fontWeight: 'bold',
      fontFamily: 'Inter, sans-serif',
      color: '#333',
      fontSize: '15px',
      lineHeight: '20px',
      marginBottom: '0.3125rem',
    },
    group: {
      backgroundColor: '#ffffff',
      borderRadius: 0,
      width: '100%',
    },
    input: {
      border: '1px solid #cccccc',
      padding: '8px 12px',
      width: '100%',
      height: '38px',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#333333',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
    },
  })
}

class Input extends Component {
  render() {
    const { classes, input, label, ...other } = this.props
    const { onChange, value } = input
    return (
      <label className={classes.label}>
        <Typography className={classes.text}>{label}</Typography>
        <OutlinedInput
          type="text"
          className={classes.group}
          inputProps={{
            className: classes.input,
          }}
          onChange={onChange}
          value={value}
          notched={false}
          {...other}
        />
      </label>
    )
  }
}

export default withStyles(styles)(Input)
