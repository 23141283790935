import React, { Component } from 'react'

import TextField from './TextField'

export class Fields extends Component {
  render() {
    return (
      <>
        <TextField name="name" label="Name" placeholder="Full Name" required />
        <TextField
          name="email"
          label="Email"
          placeholder="Email Address"
          required
          type="email"
        />
        <TextField
          name="contactNumber"
          label="Contact Number"
          placeholder="Contact Number"
          required
        />
      </>
    )
  }
}

export default Fields
